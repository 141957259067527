import HelmetTags from "../../../../MainComponents/HelmetTags"

export default function MobileApplication() {
  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light space-y-5`}
    >
        <HelmetTags title={"Mobile Application | Nawar Academy"}></HelmetTags>
    </section>
  )
}
