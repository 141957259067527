import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";
import { PiTrendUp } from "react-icons/pi";
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const { RangePicker } = DatePicker;
function AnalyticesStudentStatus() {
  const [status, setStatus] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [date, setDate] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sumbitStatus, setSumbitStatus] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [
    AnalyticesStudentStatus,
    AnalyticesStudentStatusErrors,
    AnalyticesStudentStatusLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_ANALYTICS_STATUS_STUDENTS_API,
    "POST",
    sumbitStatus.flag,
    sumbitStatus.dependency,
    sumbitStatus.data,
    true
  );

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
      setDate(formattedDates);
    }
  };

  const onSubmit = (e) => {
    if (!date.length) {
      toast.error("Please select a valid date range.");
      return;
    }
    if (date.length > 1) {
      const [from, to] = date;
      setSumbitStatus({
        flag: "Get Satus",
        depdency: !sumbitStatus.dependency,
        data: {
          from: from,
          to: to,
        },
      });
    }
  };

  useEffect(() => {
    if (AnalyticesStudentStatus) {
      setSumbitStatus({
        flag: "",
        dependency: false,
        data: {},
      });
    }
  }, [AnalyticesStudentStatus]);


  useEffect(() => {
    if (AnalyticesStudentStatus?.data) {
      if (status === 1) {
        setFilterData(AnalyticesStudentStatus?.data?.out_students);
      } else if (status === 2) {
        setFilterData(AnalyticesStudentStatus?.data?.all_restored_students);
      } else setFilterData(AnalyticesStudentStatus?.data?.created_students);
    }
  }, [AnalyticesStudentStatus, status]);

  const table = useTable(
    "studentStatus",
    "",
    "",
    filterData,
    status,
    "",
    true,
    filterData
  );

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Status Students | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">
        Status Students : {filterData?.length}
      </h2>
      <form className="w-1/2 sm:w-full space-y-5 rounded-3xl border border-secondary shadow-2xl p-5">
        <RangePicker
          className="signin-inputs"
          onChange={handleDateChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        <button type="button" className="submit" onClick={onSubmit}>
          {AnalyticesStudentStatusLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Submit"
          )}
        </button>
      </form>
      <div className="mb-20 flex flex-col items-start gap-20 mt-10">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          <button
            onClick={(event) => {
              event.preventDefault();
              setStatus(1);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              status !== 1
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Out
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              setStatus(2);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              status !== 2
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Un Out
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              setStatus(3);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              status !== 3
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            New Students
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AnalyticesStudentStatus;
