import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const AdminViewExam = () => {
  const { examID } = useParams();
  const [examData, setExamData] = useState([]);

  const [submitData, setSubmitData] = useState({
    addStudent: { flag: "", dependency: false, data: {} },
    reopenExam: { flag: "", dependency: false, data: {} },
  });

  const { register, handleSubmit, reset } = useForm({ mode: "onTouched" });

  const [dataSuccess] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_STUDENT_API,
    "POST",
    submitData.addStudent.flag,
    submitData.addStudent.dependency,
    submitData.addStudent.data,
    true
  );

  const [dataReopen] = useAxios(
    process.env.REACT_APP_ADMIN_ALL_STUDENTS_REOPEN_API,
    "POST",
    submitData.reopenExam.flag,
    submitData.reopenExam.dependency,
    submitData.reopenExam.data,
    true
  );

  const [allExamsData] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET"
  );

  useEffect(() => {
    if (allExamsData?.data) {
      setExamData(allExamsData.data.filter((exam) => exam.id !== examID));
    }
  }, [allExamsData, examID]);

  const handleAddStudent = (data) => {
    setSubmitData((prev) => ({
      ...prev,
      addStudent: {
        flag: "Add Student",
        dependency: !prev.addStudent.dependency,
        data: { number: data.number, exam_id: examID },
      },
    }));
    reset();
  };

  const handleReopenExam = (data) => {
    setSubmitData((prev) => ({
      ...prev,
      reopenExam: {
        flag: "Reopen Exam",
        dependency: !prev.reopenExam.dependency,
        data: { reopen_exam: data.reopen_exam, current_exam: examID },
      },
    }));
  };

  const table = useTable(
    "viewStudentExam",
    "",
    "",
    submitData.addStudent.dependency,
    "",
    examID
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 space-y-5">
      <HelmetTags title="Exams | Nawar Academy" />

      <h2 className="mb-20 text-4xl font-bold text-center">All Students</h2>

      {/* Add Student Form */}
      <div className="w-3/4 md:w-full mx-auto py-10">
        <div className="flex w-full shadow-lg shadow-blue-200/50">
          <div className="flex-1 flex flex-col items-center">
            <form
              className="w-full flex flex-col items-start justify-center gap-6 p-10"
              onSubmit={handleSubmit(handleAddStudent)}
            >
              <label>Mobile Number</label>
              <input
                type="text"
                placeholder="Mobile Number"
                className="signin-inputs signin-input"
                {...register("number")}
              />
              <button className="submit" type="submit">
                Add Student
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Reopen Exam Form */}
      <div className="w-3/4 md:w-full mx-auto py-10">
        <div className="flex w-full shadow-lg shadow-blue-200/50">
          <div className="flex-1 flex flex-col items-center">
            <form
              className="w-full flex flex-col items-start justify-center gap-6 p-10"
              onSubmit={handleSubmit(handleReopenExam)}
            >
              <label>Select Exam</label>
              <select {...register("reopen_exam")} className="signin-inputs">
                {examData.map((exam) => (
                  <option key={exam.key} value={exam.key}>
                    {exam.title}
                  </option>
                ))}
              </select>
              <button className="submit" type="submit">
                Reopen
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Student Exam Table */}
      <div className="h-fit w-full">{table}</div>
    </section>
  );
};

export default AdminViewExam;
