import { useEffect, useMemo, useReducer } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import QuizHeader from "./component/quizHeader/QuizHeader";
import { DisplayQuestion } from "./component/displayQuestion/DisplayQuestion";
import Question from "./component/displayQuestion/Question";
import QuizFooter from "./component/quizFooter/QuizFooter";
import RightQuestionsSidebar from "./component/quizHeader/RightQuestionsSidebar";
import DisplayAnswers from "./component/displayAnswers/DisplayAnswers";
import QuestionsAnswers from "./component/displayAnswers/QuestionsAnswers";
import PercentageSidebar from "./component/displayAnswers/PercentageSidebar";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";

function reducer(state, action) {
  switch (action.type) {
    case "setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "setCollapse": {
      return {
        ...state,
        collapse: !state.collapse,
      };
    }

    case "setQuestions": {
      return {
        ...state,
        questions: action.payload,
      };
    }

    case "setQuizInfo": {
      return {
        ...state,
        quizInfo: action.payload,
      };
    }

    case "setCurrentQuestionIndex": {
      return {
        ...state,
        collapse: false,
        currentQuestionIndex: action.payload,
      };
    }

    case "setIsShowAnswers": {
      return {
        ...state,
        isShowAnswers: true,
      };
    }

    case "setIsSelectedAnswer": {
      return {
        ...state,
        questions: state?.questions?.map((question) =>
          question.id === action.payload.questionId
            ? {
                ...question,
                selected_answer: action.payload.answer,
                is_selected: action.payload.questionId,
                n: action.payload.n,
              }
            : question
        ),
      };
    }

    case "setAnsweredQuestions": {
      const answerExists = state.answeredQuestions.find(
        (answer) => answer.id === action.payload.id
      );

      return {
        ...state,
        answeredQuestions: answerExists
          ? state.answeredQuestions.map((answer) =>
              answer.id === action.payload.id
                ? {
                    ...answer,
                    order: action.payload.order ?? answer.order,
                    n: action.payload.n ?? answer.n,
                  }
                : answer
            )
          : [...state.answeredQuestions, action.payload],
      };
    }

    case "setFinishQuizData": {
      return {
        ...state,
        finishQuizData: action.payload,
      };
    }

    case "setAllData": {
      return {
        ...initialState,
      };
    }
    case "setRefetch": {
      return {
        ...state,
        refetch: action.payload,
      };
    }

    default:
      throw Error("Unknown action: " + action.type);
  }
}

const initialState = {
  loading: true,
  currentQuestionIndex: 0,
  collapse: false,
  questions: [],
  quizInfo: {},
  answeredQuestions: [],
  finishQuizData: {},
  numOfSelected: 0,
  isShowAnswers: false,
  refetch: false,
};

const QuizNew = ({ homework, exam, quiz, retake }) => {
  const { ID } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_SHOW_QUESTIONS_API
      : process.env.REACT_APP_STUDENT_QUIZZ_API;

  const checkFinishApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_SUBMIT_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_SUBMIT_API
      : process.env.REACT_APP_STUDENT_QUIZZ_SUBMIT_API;

  const [quizData, quizErrors, quizLoading] = useAxios(
    retake
      ? process.env.REACT_APP_STUDENT_GET_RETAKES_AMOUNT_API
      : `${checkApi}/${ID}`,
    "GET",
    "GET",
    state.refetch ? state?.refetch : "",
    ""
  );

  useEffect(() => {
    if (quizData) {
      dispatch({
        type: "setQuestions",
        payload: [...quizData?.choosequestions, ...quizData?.essayquestions],
      });
      dispatch({
        type: "setQuizInfo",
        payload: quizData?.quiz || {},
      });
      // dispatch({ type: "setRefetch", payload: false }); // Ensure refetch is reset
    }
  }, [quizData]);

  useEffect(() => {
    // dispatch({
    //   type: "setAnsweredQuestions",
    //   payload: [],
    // });
    state.questions
      // .filter((question) => question.is_selected === 1)
      .map((question) => {
        dispatch({
          type: "setAnsweredQuestions",
          payload: {
            id: question.id,
            order: question.selected_answer || "E",
            n: question.n,
            eff: true,
          },
        });
        return null;
      });
  }, [state.questions]);

  //console.log("----f0", state.answeredQuestions);

  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.userData).student_token;
  }, []);

  const onFinishQuiz = () => {
    async function fetchData() {
      try {
        let res;
        if (!retake) {
          res = await axios.post(checkFinishApi, {
            lecture_id: ID,
            answers: state.answeredQuestions,
            totaldegree: state.questions
              ?.map((question) => question.degree)
              .reduce((accumulator, current) => accumulator + current),
          });
          dispatch({ type: "setFinishQuizData", payload: res.data.data });
          dispatch({ type: "setIsShowAnswers" });
        } else {
          res = await axios.post(
            process.env.REACT_APP_STUDENT_ADD_RETAKES_API,
            {
              answers: [state.answeredQuestions[state.currentQuestionIndex]],
            }
          );

          if (res.data.data.is_correct === 0) {
            toast.error("Wrong Answer");
          } else {
            dispatch({ type: "setRefetch", payload: !state.refetch });
          }
        }

        // dispatch({ type: "setAnsweredQuestions", payload: [] });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        // dispatch({ type: "setAllData" });
      }
    }
    fetchData();
  };

  if (quizLoading) return <Loader />;

  return (
    <section
      className={`flex h-fit  min-h-screen w-full flex-col items-center `}
    >
      <HelmetTags
        title={`${
          homework ? "Homework" : exam ? "Exams" : "Quiz"
        } | Nawar Academy `}
      ></HelmetTags>
      <QuizHeader
        state={state}
        dispatch={dispatch}
        onFinishQuiz={onFinishQuiz}
        quizErrors={quizErrors}
        quizLoading={quizLoading}
        exam={exam}
      />

      {quizErrors && (
        <div className="flex justify-center flex-col mt-12">
          <p>{quizErrors?.response?.data.message}</p>
          {!retake && (
            <Link
              to={`${exam ? `/exams/${ID}` : `/lectures/sessions/${ID}`}`}
              className="flex justify-center my-6"
            >
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                    state.collapse && "rotate-180"
                  }`}
                  icon={faChevronRight}
                />
              </div>
            </Link>
          )}
        </div>
      )}

      {/* {quizLoading && <p>loading...</p>} */}

      {!quizLoading && !quizErrors && (
        <main
          className={`w-full min-h-full md:min-h-fit md:flex-col md:gap-[40px] flex max-w-[1700px] justify-center  pb-[130px] ${
            !state.isShowAnswers && "flex-col"
          }`}
        >
          {!state.isShowAnswers ? (
            <DisplayQuestion state={state}>
              <Question
                state={state}
                dispatch={dispatch}
                homework={homework}
                exam={exam}
                retake={retake}
              />
              {!retake && !exam && (
                <RightQuestionsSidebar state={state} dispatch={dispatch} />
              )}
              {retake || exam ? (
                <button
                  onClick={onFinishQuiz}
                  disabled={
                    state.answeredQuestions.filter(
                      (question) => question.order === "E"
                    ).length > 0
                      ? true
                      : false
                  }
                  className="mt-10 submit-btn rounded-md border-light bg-secondary px-[16px]  py-[8px]  font-bold text-light duration-200 hover:scale-110 active:scale-90 "
                >
                  Submit
                </button>
              ) : null}

              {!exam && (
                <QuizFooter
                  state={state}
                  dispatch={dispatch}
                  onFinishQuiz={onFinishQuiz}
                  retake={retake}
                />
              )}

              {retake && (
                <RightQuestionsSidebar state={state} dispatch={dispatch} />
              )}
            </DisplayQuestion>
          ) : (
            <DisplayAnswers>
              <QuestionsAnswers
                state={state}
                dispatch={dispatch}
                exam={exam}
                homework={homework}
                quiz={quiz}
              />
              <PercentageSidebar state={state} />
            </DisplayAnswers>
          )}
        </main>
      )}
    </section>
  );
};

export default QuizNew;
