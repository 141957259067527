import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { faGear, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxios from "../../../../MainComponents/Hooks/useAxios";

const AssistatsChangePermissions = () => {
  const [permissionsData, setPermissionsData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { assistantID } = useParams("");

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add assistant -------

  const [
    GetAssistantPermissionsSuccess,
    GetAssistantPermissionsErrors,
    GetAssistantPermissionsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_UPDATE_ASSISTANT_GET_PERMISSIONS_API}/${assistantID}`,
    "GET",
    "GET",
    ""
  );

  const [
    EditAssistantPermissionsSuccess,
    EditAssistantPermissionsErrors,
    EditAssistantPermissionsLoading,
  ] = useAxios(
    process.env.REACT_APP_UPDATE_ASSISTANT_UPDATE_PERMISSIONS_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    const FormArray = Object.entries(formData);
    const permissions = [];
    FormArray.map((entry) => {
      if (entry[1] === true) {
        permissions.push(entry[0]);
        return;
      } else if (entry[1] === false) {
        return;
      }
      return entry;
    });

    setFormValues({ id: assistantID, permissions: permissions });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditAssistantPermissionsSuccess) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantPermissionsSuccess]);

  useEffect(() => {
    if (EditAssistantPermissionsErrors) {
      toast.error(EditAssistantPermissionsErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantPermissionsErrors]);

  useEffect(() => {
    if (GetAssistantPermissionsSuccess) {
      setPermissionsData([...GetAssistantPermissionsSuccess?.data]);
    }
  }, [GetAssistantPermissionsSuccess]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl   p-10 shadow-lg shadow-blue/50 my-6"
    >
      <h2 className="mb-3 flex w-full items-center justify-center gap-2  text-[17px] font-bold">
        <span>Change permissions</span>
        <FontAwesomeIcon className="ml-2 w-8" icon={faGear} />
      </h2>

      {permissionsData
        ?.filter((permission) => permission.section !== "Alrehla permissions")
        .map((permission, i) => (
          <div
            key={i}
            className="student-permissions mt-0 flex h-fit w-full flex-col gap-7"
          >
            <h2 className="flex w-full items-center justify-start text-[17px] font-bold">
              <span>{permission.section} </span>
            </h2>
            <div className="input-label-container flex h-fit w-full flex-wrap-reverse items-center justify-evenly gap-10 rounded-3xl border border-blue p-10 ">
              {permission?.permissions.map((permission) => (
                <div
                  key={permission.id}
                  className=" flex w-fit  items-center justify-center gap-16 md:w-full"
                >
                  <div className="checkbox-wrapper">
                    <div className="cbx">
                      <input
                        id={permission.name}
                        className="signin-inputs signin-input w-full"
                        type="checkbox"
                        name={permission.name}
                        defaultChecked={permission.is_selected}
                        {...register(permission.name)}
                      />
                      <label htmlFor={permission.name}></label>
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path d="M2 8.36364L6.23077 12L13 2"></path>
                      </svg>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                      <defs>
                        <filter id="goo-12">
                          <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="4"
                            result="blur"
                          ></feGaussianBlur>
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                            result="goo-12"
                          ></feColorMatrix>
                          <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                        </filter>
                      </defs>
                    </svg>
                  </div>

                  <label
                    className="w-fit cursor-pointer text-end"
                    htmlFor={permission.name}
                  >
                    {permission.public_name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}

      {/** Submit Button */}
      <button
        // disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {EditAssistantPermissionsLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit Permissions"
        )}
      </button>
    </form>
  );
};

export default AssistatsChangePermissions;
