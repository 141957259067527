import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
export const BunnyVideo = ({ src }) => {
  const [minWatchTime, setMinWatchTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [pausedTime, setPausedTime] = useState(0);
  let videoRef = null;

  const handlePlay = () => {
    setStartTime(Date.now());
  };

  const handlePause = () => {
    setPausedTime(Date.now() - startTime + pausedTime);
  };

  const handleTimeUpdate = () => {
    // Calculate the total time the video has been played
    const currentWatchTime = Math.floor(videoRef.currentTime / 60);
    setMinWatchTime(currentWatchTime);
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      // Reset the video and states when unmounting
      if (videoRef) {
        videoRef.pause();
        setMinWatchTime(0);
        setStartTime(null);
        setPausedTime(0);
      }
    };
  }, []);

  return (
    <iframe
      src={src}
      width="100%"
      height="100%"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      id="courseVideo"
    ></iframe>
    // <ReactPlayer
    //   url={`https://iframe.mediadelivery.net/play/159923/a687056b-e0ba-4f59-a349-f16a193ca654`}
    //   controls // Enable player controls
    //   playing // Start playing the video automatically
    //   onPlay={handlePlay}
    //   onPause={handlePause}
    //   width="100%"
    //   height="100%"
    // />

    // {/* <ReactPlayer
    //   url={src}
    //   controls
    //   playing
    //   width="100%"
    //   height="100%"
    //   onPlay={handlePlay}
    //   onPause={handlePause}
    // />
    // <iframe
    //   src={src}
    //   loading="lazy"
    //   width="100%"
    //   height="100%"
    //   allowFullScreen
    //   id="courseVideo"
    //   onPlay={handlePlay}
    //   onPause={handlePause}
    //   allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    //   allowfullscreen="true"
    //   title="bunny"
    // ></iframe> */}
    // {/* <video
    //   ref={(ref) => (videoRef = ref)}
    //   src={src}
    //   controls
    //   autoPlay
    //   width="100%"
    //   height="100%"
    //   onPlay={handlePlay}
    //   onPause={handlePause}
    //   // onTimeUpdate={handleTimeUpdate}
    // /> */}
  );
};
