import React, { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { DatePicker } from "antd";
import { useParams } from "react-router-dom";

export default function AddEditReopen() {
  const { lectureID, Revision } = useParams();
  const [date, setDate] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const handleChangeDate = (date) => {
    if (date) {
      setDate(date);
    }
  };
  const [DataReopen, ErrorReopen, LoadingReopen] = useAxios(
    process.env.REACT_APP_ADMIN_REOPEN_LECTURE_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = () => {
    setSubmitAdd({
      flag: "Reopen Lecture",
      depdency: !submitAdd.depdency,
      data: {
        is_revision: Revision === 1 ? 1 : 0,
        lecture_id: lectureID,
        expired_at: date,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Reopen | Nawar Academy"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">Reopen</h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          <DatePicker
            className="signin-inputs"
            showTime
            onChange={(_, dataString) => handleChangeDate(dataString)}
          />

          <button
            disabled={!isValid || LoadingReopen}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {LoadingReopen ? "Loading..." : "Reopen"}
          </button>
        </form>
      </div>
    </section>
  );
}
