import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";

function DeletedStudents() {
  // const table = useTable("deletedStudents");
  const [academiclevel, setAcademiclevel] = useState(false);
  

  const [
    getAllDeletedStudents,
    getAllDeletedStudentsErrors,
    getAllDeletedStudentsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ALL_DELETED_STUDENTS_API}/${academiclevel}`,
    "GET",
    academiclevel,
    academiclevel
  );

 

  const table = useTable(
    "deletedStudents",
    "",
    "",
    academiclevel,
    "",
    academiclevel
  );

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Out Students | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">
        Out Students {getAllDeletedStudents?.data?.length}
      </h2>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          <button
            onClick={(event) => {
              event.preventDefault();
              setAcademiclevel(1);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 1
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 1
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              setAcademiclevel(2);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 2
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 2
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              setAcademiclevel(3);
            }}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 3
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 3
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default DeletedStudents;
