/* eslint-disable import/first */
import { Suspense, lazy } from "react";
const LazyLandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
const LazyReservationsPage = lazy(
  () => import("./Pages/LandingPage/ReservationPage")
);
const LazyLocationsPage = lazy(
  () => import("./Pages/LandingPage/LocationsPage")
);
// eslint-disable-next-line import/first
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./SASS/styles.scss";

import { Layout, Loader, NotFound } from "./MainComponents";
import {
  SignIn,
  Register,
  StudentLectures,
  StudentChapter,
  StudentLecture,
  Exams,
  SingleExamPage,
  HomePage,
  StudentQuestionBank,
  QuizNew,
  StudentShowQuestionBank,
  Profile,
  Amount,
  Invitations,
} from "./Pages/Student";
import {
  MainLayout,
  Posts,
  AddEditPost,
  Students,
  DeletedStudents,
  EditStudent,
  Attendance,
  AdminLectures,
  AddEditChapter,
  AddEditLecture,
  Videos,
  AdminMaterials,
  AddEditMaterial,
  AddEditVideo,
  AdminChapter,
  AdminLecture,
  AdminQuiz,
  AdminExams,
  Groups,
  AddEditGroup,
  Codes,
  Requests,
  SessionStudents,
  SessionAbsence,
  Assistants,
  AddEditAssistant,
  QuestionBank,
  QuestionBankCategory,
  AddEditEssayQuestion,
  AddEditChooseQuestion,
  AddEditCategory,
  Whatsapp,
  ViewStudent,
  CodesTracker,
  AdminProfile,
  AdminViewExam,
  AdminAddStudentLecture,
  Center,
  CenterViewTable,
  CenterStudentsView,
  ChatAdmin,
  Analytics,
  AnalyticsBlocking,
  AdminCourses,
  AddEditCourse,
  ActivationCodes,
  Reviews,
  EditStudentSession,
  CenterStudentsGroupFilter,
  AdminStudentsCourses,
  CenterGroup,
  AdminDiscountStudents,
  BlockedStudents,
  LateStudents,
  GivechanceStudent,
  OnlineStudentsGroup,
  Home,
  OnlineViewExamTable,
  OnlineCenterExamsViewTable,
  CenterExamViewTable,
  AdminFiles,
  AdminReservations,
  AdminCreateApoiment,
  AdminReservationsStudents,
  AdminAppointmentStudents,
  AdminRevisionCodes,
  AdminAddEditExam,
} from "./Pages/Admin";

import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes";
import { useAuthState } from "./MainComponents/GlobalContext.jsx";
import ModalAnswer from "./Pages/Student/Lectures/modalAnswer/ModalAnswer";

import ForgetPassword from "./Pages/Student/SignIn/ForgetPassword.jsx";
import AdminExamAbsent from "./Pages/Admin/Dashboard/AdminExams/AdminExamAbsent.jsx";
import AnalyticesTopTenWrong from "./Pages/Admin/Dashboard/Analytics/AnalyticesTopTenWrong.jsx";
import StudentResults from "./Pages/Student/Results/StudentResults.jsx";

import AnalyticesWrongStudents from "./Pages/Admin/Dashboard/Analytics/AnalyticesWrongStudents.jsx";
import ResultModalAnswer from "./Pages/Student/Results/ResultsModalAnswer.jsx";
import AdminThreed from "./Pages/Admin/Dashboard/3D/AdminThreed.jsx";
import AdminSingleThreed from "./Pages/Admin/Dashboard/3D/AdminSingleThreed.jsx";
import AddEditThreed from "./Pages/Admin/Dashboard/3D/AddEditThreed.jsx";
import AdminViewThreed from "./Pages/Admin/Dashboard/3D/AdminViewThreed.jsx";
import Threed from "./Pages/Student/3dModels/ThreeD.jsx";
import ViewThreeds from "./Pages/Student/3dModels/ViewThreeds.jsx";
import SingleThreeD from "./Pages/Student/3dModels/SingleThreeD.jsx";
import AdminExamVideos from "./Pages/Admin/Dashboard/AdminExamVideos/AdminExamVideos.jsx";
import AddEditExamVideos from "./Pages/Admin/Dashboard/AdminExamVideos/AddEditExamVideos.jsx";
import ExamVideos from "./Pages/Student/StudentExamVideos/ExamVideos.jsx";
import MobileApplication from "./Pages/Admin/Dashboard/MobileApplication/MobileApplication.jsx";
import AddEditReopen from "./Pages/Admin/Dashboard/Lectures/AddEditReopen.jsx";
import AdminModalAnswer from "./Pages/Admin/Dashboard/AdminExams/AdminModalAnswer.jsx";
import AnalyticesStudentStatus from "./Pages/Admin/Dashboard/Analytics/AnalyticesStudentStatus.jsx";
import OnlineStudentsGroupFilter from "./Pages/Admin/Dashboard/Center/OnlineStudentsGroupFilter.jsx";
import Alrehla from "./Pages/Admin/Dashboard/Alrehla/Alrehla.jsx";
import AddEditAlrehla from "./Pages/Admin/Dashboard/Alrehla/AddEditAlrehla.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------
    {
      path: "/",
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/home"
          }`}
          isAllowed={!AuthState.userData}
        >
          <Suspense fallback={<Loader />}>
            <LazyLandingPage />
          </Suspense>
        </ProtectedAdminRoutes>
      ),
    },
    {
      path: "/locations",
      element: (
        <Suspense fallback={<Loader />}>
          <LazyLocationsPage />
        </Suspense>
      ),
    },
    {
      path: "/reservations/:reservationID",
      element: (
        <Suspense fallback={<Loader />}>
          <LazyReservationsPage />
        </Suspense>
      ),
    },
    //!Student Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <MainLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/home/amount",
          element: <Amount />,
        },
        {
          path: "/home/invitations",
          element: <Invitations />,
        },
        {
          path: "/retake",
          element: <QuizNew retake />,
        },
        {
          path: "/lectures",
          element: <StudentLectures />,
        },
        {
          path: "/lectures/chapters/:chapterID",
          element: <StudentChapter />,
        },
        {
          path: "/lectures/sessions/:lectureID",
          element: <StudentLecture />,
        },
        {
          path: "/lectures/sessions/modal-answer/:ID",
          element: <ModalAnswer />,
        },

        {
          path: "/revisions",
          element: <StudentLectures revision />,
        },
        {
          path: "/revisions/sessions/:lectureID",
          element: <StudentLecture revision />,
        },
        {
          path: "/examvideos",
          element: <ExamVideos />,
        },

        {
          path: "/courses",
          element: <StudentLectures course />,
        },
        {
          path: "/courses/sessions/:lectureID",
          element: <StudentLecture />,
        },
        {
          path: "/exams",
          element: <Exams />,
        },
        {
          path: "/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/exams/solve-exam/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //!Results
        {
          path: "/results",
          element: <StudentResults />,
        },
        {
          path: "/results/modal-answer/:ID",
          element: <ResultModalAnswer />,
        },
        //!Quiz
        {
          path: "/home/quiz/:ID",
          element: <QuizNew quiz />,
        },
        //!Homework
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        //! Student Question Bank
        {
          path: "/home/question-bank",
          element: <StudentQuestionBank />,
        },
        {
          path: "/home/question-bank/:ID",
          element: <StudentShowQuestionBank />,
        },
        //! Student 3d Models
        {
          path: "/3d-models",
          element: <Threed />,
        },
        {
          path: "/3d-models/model/:chapterID",
          element: <ViewThreeds />,
        },
        {
          path: "/threads/chapters/:threedID",
          element: <SingleThreeD />,
        },
      ],
    },
    //!Admin Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/admin/signin"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <MainLayout admin />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!--------- dashboard Routes --------------
        {
          path: "/admin/dashboard",
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions
              }
            >
              <Home />
            </ProtectedAdminRoutes>
          ),
        },
        {
          path: "/admin/posts",
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions
              }
            >
              <Posts />
            </ProtectedAdminRoutes>
          ),
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("post_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/dashboard/edit-post/:postID",
              element: <AddEditPost edit />,
            },
          ],
        },
        //!------ students Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/signin"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/late-students",
              element: <LateStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/deleted",
              element: <DeletedStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/blocked",
              element: <BlockedStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                ) ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("Alrehla")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent edit />,
            },
            {
              path: "/admin/students/change-session/:sessionID/:stage",
              element: <GivechanceStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudent />,
            },
          ],
        },
        //!------ 3D THREED Routes --------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("3d_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/threeds",
              element: <AdminThreed />,
            },
            {
              path: "/admin/threeds/view/:chapterID",
              element: <AdminViewThreed />,
            },
            {
              path: "/admin/threeds/threed/:threedID",
              element: <AdminSingleThreed />,
            },
            {
              path: "/admin/threes/threed/add-threed/:chapterID",
              element: <AddEditThreed />,
            },
            {
              path: "/admin/threes/threed/edit-threed/:threedID",
              element: <AddEditThreed edit />,
            },
          ],
        },
        //!------ center Routes --------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/center",
              element: <Center />,
            },
            {
              path: "/admin/center/students",
              element: <CenterStudentsView />,
            },
            {
              path: "/admin/center/students/:stageId/:groupId",
              element: <CenterStudentsGroupFilter />,
            },
            {
              path: "/admin/center/attendance",
              element: <CenterViewTable tableFor="attendanceGroup" />,
            },
            {
              path: "admin/center/attendance/:groupId",
              element: <CenterGroup tableFor="attendanceGroupShowSessions" />,
            },
            {
              path: "admin/center/quizzes/:groupId",
              element: <CenterGroup tableFor="quizzesGroupShowSessions" />,
            },
            {
              path: "/admin/center/quizzes",
              element: <CenterViewTable tableFor="quizzesGroup" />,
            },
            {
              path: "/admin/center/exams",
              element: <CenterViewTable tableFor="examsGroup" />,
            },
            {
              path: "/admin/center/exams/:groupID/:stage",
              element: <CenterExamViewTable tableFor="allCenterExams" />,
            },
            {
              path: "/admin/center/exams/:groupID/:stage/:examID",
              element: <OnlineCenterExamsViewTable />,
            },
            {
              path: "/admin/center/groups",
              element: <CenterViewTable tableFor="groupsGroup" />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some(
                  (permission) =>
                    permission.name.startsWith("student") &&
                    permission.name !== "student_count"
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/online",
              element: <Center online />,
            },
            {
              path: "/admin/online/students",
              element: <CenterStudentsView online />,
            },
            {
              path: "/admin/online/students/:stage",
              element: <OnlineStudentsGroup online filter />,
            },
            {
              path: "/admin/online/students/:stageId/:groupId",
              element: <OnlineStudentsGroupFilter />,
            },
            {
              path: "/admin/online/attendance",
              element: <CenterStudentsView online attendance />,
            },
            {
              path: "/admin/online/attendance/:stage",
              element: <OnlineStudentsGroup online attendance />,
            },
            {
              path: "admin/online/attendance/:groupId",
              element: <CenterGroup tableFor="attendanceGroupShowSessions" />,
            },
            {
              path: "admin/online/quizzes/:groupId",
              element: <CenterGroup tableFor="quizzesGroupShowSessions" />,
            },

            {
              path: "/admin/online/quizzes",
              element: <CenterViewTable tableFor="quizzesGroup" />,
            },
            {
              path: "/admin/online/exams",
              element: <OnlineViewExamTable online />,
            },
            {
              path: "admin/online/exams/:examID",
              element: <OnlineCenterExamsViewTable online />,
            },
            {
              path: "/admin/online/groups",
              element: <CenterViewTable tableFor="groupsGroup" />,
            },
          ],
        },
        //!------ Attendance Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance",
              element: <Attendance />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID",
              element: <SessionStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID/absence",
              element: <SessionAbsence />,
            },
          ],
        },
        //!------ Lectures Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures",
              element: <AdminLectures />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/add-chapter",
              element: <AddEditChapter />,
            },
            {
              path: "/admin/lectures/add-lecture",
              element: <AddEditLecture />,
            },
            {
              path: "/admin/lectures/:chapterID/add-lecture",
              element: <AddEditLecture />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/edit-chapter/:chapterID",
              element: <AddEditChapter edit />,
            },
            {
              path: "/admin/lectures/edit-lecture/:lectureID",
              element: <AddEditLecture edit />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/chapters/:chapterID",
              element: <AdminChapter />,
            },
            {
              path: "/admin/lectures/sessions/:lectureID",
              element: <AdminLecture />,
            },
            {
              path: "/admin/lectures/sessions/:recordID/edit-student",
              element: <EditStudentSession edit />,
            },
            {
              path: "/admin/courses/sessions/:recordID/edit-student",
              element: <EditStudentSession edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addstudent")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/add-student",
              element: <AdminAddStudentLecture />,
            },
            {
              path: "/admin/lectures/sessions/:lectureID/reopen/:Revision",
              element: <AddEditReopen />,
            },
            {
              path: "/admin/revisions/sessions/:lectureID/reopen/:Revision",
              element: <AddEditReopen />,
            },
            {
              path: "/admin/courses/sessions/:lectureID/add-student",
              element: <AdminAddStudentLecture />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses",
              element: <AdminCourses />,
            },
            {
              path: "/admin/students-courses",
              element: <AdminStudentsCourses />,
            },
            {
              path: "/admin/students-courses/edit-student/:studentID",
              element: <EditStudent edit />,
            },
            {
              path: "/admin/courses/add-course",
              element: <AddEditCourse />,
            },
            {
              path: "/admin/courses/edit-course/:courseID",
              element: <AddEditCourse edit />,
            },
            {
              path: "/admin/courses/sessions/:courseID",
              element: <AdminLecture edit course />,
            },

            {
              path: "/admin/courses/sessions/:lectureID/videos",
              element: <Videos course />,
            },
            {
              path: "/admin/courses/:lectureID/add-video",
              element: <AddEditVideo course />,
            },
            {
              path: "/admin/videos/edit-video/:VideoID",
              element: <AddEditVideo edit />,
            },
          ],
        },
        //!--- lecture_addvideos ---
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addvideos")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/videos",
              element: <Videos />,
            },
            {
              path: "/admin/lectures/:lectureID/add-video",
              element: <AddEditVideo />,
            },
            {
              path: "/admin/videos/edit-video/:VideoID",
              element: <AddEditVideo edit />,
            },
          ],
        },
        //!--- lecture_addmatrial ---
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addmatrial")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/materials",
              element: <AdminMaterials />,
            },
            {
              path: "/admin/lectures/:lectureID/add-material",
              element: <AddEditMaterial />,
            },
            {
              path: "/admin/courses/sessions/:lectureID/materials",
              element: <AdminMaterials />,
            },
            {
              path: "/admin/courses/:lectureID/add-material",
              element: <AddEditMaterial />,
            },
          ],
        },
        //!------ Revisions Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions",
              element: <AdminLectures revision />,
            },
            {
              path: "/admin/revisions/codes/:lectureID",
              element: <AdminRevisionCodes revision />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/add-revision",
              element: <AddEditLecture revision />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/edit-revision/:lectureID",
              element: <AddEditLecture edit revision />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID",
              element: <AdminLecture revision />,
            },
            {
              path: "/admin/revisions/sessions/:recordID/edit-student",
              element: <EditStudentSession edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addstudent")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID/add-student",
              element: <AdminAddStudentLecture revision />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addmatrial")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID/materials",
              element: <AdminMaterials revision />,
            },
            {
              path: "/admin/revisions/:lectureID/add-material",
              element: <AddEditMaterial />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addvideos")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID/videos",
              element: <Videos revision />,
            },
            {
              path: "/admin/revisions/:lectureID/add-video",
              element: <AddEditVideo />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addquiz")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID/quiz",
              element: <AdminQuiz />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addHw")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/revisions/sessions/:lectureID/homework",
              element: <AdminQuiz homework />,
            },
          ],
        },
        // files
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("file_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/files",
              element: <AdminFiles />,
            },
          ],
        },
        //!--- lecture_quiz ---

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addquiz")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/quiz",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/lectures/sessions/quiz/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit lectureQuestion />,
            },
            {
              path: "/admin/lectures/sessions/quiz/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit lectureQuestion />,
            },
          ],
        },
        //!--- lecture_homework ---

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addHw")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/homework",
              element: <AdminQuiz homework />,
            },
            {
              path: "/admin/lectures/sessions/homework/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit lectureQuestion />,
            },
            {
              path: "/admin/lectures/sessions/homework/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit lectureQuestion />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams",
              element: <AdminExams />,
            },
            {
              path: "/admin/exams/absent-students/:examID",
              element: <AdminExamAbsent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/add-exam",
              element: <AdminAddEditExam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/edit-exam/:ID",
              element: <AdminAddEditExam edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/all-students/:examID",
              element: <AdminViewExam />,
            },
            {
              path: "/admin/exams/all-students/:examID/model-answer",
              element: <AdminModalAnswer />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_addquestions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/:examID",
              element: <AdminQuiz exam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank",
              element: <QuestionBank />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID",
              element: <QuestionBankCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addcategory")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/add-category",
              element: <AddEditCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID/add-essay-question",
              element: <AddEditEssayQuestion />,
            },
            {
              path: "/admin/question-bank/:categoryID/add-choose-question",
              element: <AddEditChooseQuestion />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_editquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/question-bank/:categoryID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups",
              element: <Groups />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reservations",
              element: <AdminReservations />,
            },
            {
              path: "/admin/reservations/appointments/:reservationID",
              element: <AdminCreateApoiment />,
            },
            {
              path: "/admin/reservations/students/:reservationID",
              element: <AdminReservationsStudents />,
            },
            {
              path: "/admin/reservations/appointments/students/:appointmentID",
              element: <AdminAppointmentStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups/add-group",
              element: <AddEditGroup />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups/:groupID/edit-group",
              element: <AddEditGroup edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes",
              element: <Codes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/activation-code",
              element: <ActivationCodes activation />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/vodafone-cash-codes",
              element: <Codes vodafone />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_tracker")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes-tracker",
              element: <CodesTracker />,
            },
            {
              path: "/admin/activation-code-tracker",
              element: <CodesTracker activation />,
            },
            {
              path: "/admin/vodafone-codes-tracker",
              element: <CodesTracker vodafone />,
            },
          ],
        },
        //!---- Analytics route ----
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("analytics")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/analytics",
              element: <Analytics />,
            },
            {
              path: "/admin/analytics/blocking",
              element: <AnalyticsBlocking />,
            },
            {
              path: "/admin/analytics/toptenwrong",
              element: <AnalyticesTopTenWrong />,
            },
            {
              path: "/admin/analytics/topwrongstudents",
              element: <AnalyticesWrongStudents />,
            },
            {
              path: "/admin/analytics/student-status",
              element: <AnalyticesStudentStatus />,
            },
          ],
        },
        // edit chat permissions
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("ask")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/chat",
              element: <ChatAdmin />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("superadmin_chats")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/all-chats",
              element: <ChatAdmin superadmin />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/requests",
              element: <Requests />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_discount")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/discount-students",
              element: <AdminDiscountStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={true}
              // isAllowed={
              //   AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              //   AuthState.userData?.admin?.permissions?.some((permission) =>
              //     permission.name.startsWith("request_accept")
              //   )
              // }
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("user")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("alrehla")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/alrehla",
              element: <Alrehla />,
            },
            {
              path: "/admin/alrehla/add-alrehla",
              element: <AddEditAlrehla />,
            },
            {
              path: "/admin/alrehla/edit-alrehla/:alrehlaID",
              element: <AddEditAlrehla edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("video_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/examvideos",
              element: <AdminExamVideos />,
            },
            {
              path: "/admin/examvideos/add-exam-video",
              element: <AddEditExamVideos />,
            },
            {
              path: "/admin/examvideos/edit-exam-video/:VideoID",
              element: <AddEditExamVideos edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("whatsapp_show")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/whatsapp",
              element: <Whatsapp />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("reviews")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reviews",
              element: <Reviews />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/mobile-application",
              element: <MobileApplication />,
            },
          ],
        },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/lectures"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn

        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <main className="dar:text-light text-secondary">
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </main>
  );
}

export default App;
