import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Doughnut } from "react-chartjs-2";
const { RangePicker } = DatePicker;

export default function WrongQuestions() {
  const [date, setDate] = useState([]);
  const [answersCount, setAnswersCount] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const [successData, errorData, loadingSuccess] = useAxios(
    process.env.REACT_APP_ANALYTICS_TOP_WRONG_QUESTIONS_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
      setDate(formattedDates);
      setValue("dateRange", formattedDates);
    }
  };

  const onSubmit = (data) => {
    if (!date.length) {
      toast.error("Please select a valid date range.");
      return;
    }

    const [from, to] = date;

    setSubmitAdd({
      flag: "Change Table",
      depdency: !submitAdd.depdency,
      data: {
        from,
        to,
        stage: data.stage,
        limit: data.limit,
      },
    });
  };

  const exportPDF = () => {
    const input = document.getElementById("export-pdf");

    html2canvas(input, {
      useCORS: true,
      scale: 2,
      backgroundColor: "#ffffff",
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF("p", "mm", [imgWidth, imgHeight]); // Dynamically set page height

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("Wrong Questions.pdf");
    });
  };
  return (
    <div className="px-10 py-10 flex flex-col gap-5 overflow-hidden ">
      {/* Form Section */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl shadow-2xl p-4"
      >
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleDateChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        {errors.dateRange && (
          <p className="text-red-500">{errors.dateRange.message}</p>
        )}

        {/* Stage Selector */}
        <select
          className="signin-inputs"
          {...register("stage", { required: "Stage selection is required" })}
        >
          <option value="">Select a stage</option>
          <option value="1">Stage 1</option>
          <option value="2">Stage 2</option>
          <option value="3">Stage 3</option>
        </select>
        {errors.stage && <p className="text-red-500">{errors.stage.message}</p>}

        {/* Limit Input */}
        <input
          type="number"
          min={1}
          max={50}
          className="signin-inputs"
          placeholder="Limit of questions"
          {...register("limit", {
            required: "Limit is required",
            min: { value: 1, message: "Minimum limit is 1" },
            max: { value: 50, message: "Maximum limit is 50" },
          })}
        />
        {errors.limit && <p className="text-red-500">{errors.limit.message}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="submit"
          disabled={loadingSuccess || !isValid}
        >
          {loadingSuccess ? <Spin /> : "Submit"}
        </button>
      </form>

      {/* PDF Export Button */}
      {successData && successData.data.length > 0 && (
        <button
          onClick={exportPDF}
          className=" w-[200px] text-white rounded-lg border-2 border-red-500 bg-red-500 px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-white hover:text-red-500   active:scale-90 "
        >
          Export PDF
          <FontAwesomeIcon className="ml-2" icon={faDownload} />
        </button>
      )}

      {/* Question Cards */}
      <div
        className="flex gap-5 flex-wrap w-full m-0 p-0 overflow-hidden "
        id="export-pdf"
      >
        {loadingSuccess
          ? null
          : successData &&
            successData.data.map((question, index) => {
              const doughnutData = {
                labels: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
                datasets: [
                  {
                    label: "Answers",
                    data: [
                      question.answer1_count,
                      question.answer2_count,
                      question.answer3_count,
                      question.answer4_count,
                    ],
                    backgroundColor: [
                      "#4285f4",
                      "rgb(110, 231, 183)",
                      "rgba(189, 19, 236)",
                      "rgba(255, 232, 236, 1)",
                    ],
                    borderColor: [
                      "rgba(3, 65, 221, 1)",
                      "rgb(110, 231, 183,1)",
                      "rgba(189, 19, 236, 1)",
                      "rgba(255, 232, 236, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              };
              return (
                <div
                  key={question.id}
                  className="w-full  p-4 rounded-3xl  border border-secondary bg-white"
                >
                  <div className="flex flex-col gap-3">
                    {/* Question Number */}
                    <div className="w-full text-center">
                      <p className="text-2xl font-bold">
                        {question.quiz_title}
                      </p>
                    </div>

                    <p className="text-3xl font-bold">Question {index + 1} </p>
                    <div className="line-chart  flex max-h-[510px] w-full flex-col items-start justify-start gap-4  lg:items-center xs:w-[80%]">
                      <Doughnut
                        data={doughnutData}
                        width={200}
                        height={200}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      ></Doughnut>
                    </div>
                    <div className="w-full flex justify-between flex-wrap">
                      <p className="text-2xl text-red-500">
                        Wrong Counts: {question.wrong_count}{" "}
                      </p>
                      <p className="text-2xl text-red-500">
                        degree: {+question.degree}{" "}
                      </p>
                    </div>

                    {/* Question */}
                    <p
                      className="text-gray-700 object-cover "
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    ></p>

                    {/* Answers */}
                    <div className="grid grid-cols-2 gap-3">
                      {["answer1", "answer2", "answer3", "answer4"].map(
                        (key) => (
                          <div
                            key={key}
                            className={`p-2 rounded ${
                              question.correct_answer === key
                                ? "bg-green border border-green-500 text-white"
                                : "bg-gray-100 border border-gray-300 "
                            }`}
                          >
                            <p className="font-semibold">{key.toUpperCase()}</p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: question[key],
                              }}
                            ></p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}
