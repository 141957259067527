import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEye,
  faEyeSlash,
  faSpinner,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const AlrehlaInformations = ({ values }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { alrehlaID } = useParams("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  //!--------- add edit assistant -------

  const [
    EditAssistantInformationSuccess,
    EditAssistantInformationErrors,
    EditAssistantInformationLoading,
  ] = useAxios(
    process.env.REACT_APP_UPDATE_ASSISTANT_UPDATE_INFO_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues({ id: alrehlaID, ...formData });
    setFormFlag("EditAssistantsInformations");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditAssistantInformationSuccess) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantInformationSuccess]);

  useEffect(() => {
    if (EditAssistantInformationErrors) {
      toast.error(EditAssistantInformationErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditAssistantInformationErrors]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
    >
      <h2 className="-mb-3 flex w-full items-center justify-center  text-[17px] font-bold">
        <span>Basic information</span>
        <FontAwesomeIcon className="ml-2 w-8" icon={faUserTie} />
      </h2>

      {/** Name filed */}
      <div className=" w-full ">
        <div className=" flex  w-full flex-col items-start  justify-center gap-2">
          <label htmlFor="name">Name</label>
          <input
            className="signin-inputs signin-input w-full pl-4"
            type="text"
            id="name"
            placeholder="Name"
            name="name"
            autoComplete="on"
            {...register("name", {
              required: true,
              //pattern: /^[A-Za-z]+$/,
              maxLength: 40,
              minLength: 3,
            })}
          />
          {errors.name && (
            <p className="text-xs text-blue-500  ">
              {errors.name.type === "required" && "Please fill this out"}
              {errors.name.type === "pattern" && "Only English allowed"}
              {errors.name.type === "maxLength" && "Maximum characters is 40"}
              {errors.name.type === "minLength" &&
                "Please type at least 3 letters"}
            </p>
          )}

          {
            //!-------server errors -----

            EditAssistantInformationErrors &&
              EditAssistantInformationErrors?.response?.data?.errors?.name && (
                <p className="text-xs text-blue-500  ">
                  {
                    EditAssistantInformationErrors?.response?.data?.errors
                      ?.name[0]
                  }
                </p>
              )
          }
        </div>
      </div>

      {/** Username and mobile fields */}
      <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
        {/* Username field */}
        <div className="flex w-1/2 flex-col items-start justify-center gap-2 md:order-1 md:w-full">
          <label htmlFor="username">User name</label>
          <input
            className="signin-inputs signin-input w-full"
            type="text"
            id="username"
            placeholder="User name"
            name="username"
            autoComplete="on"
            {...register("username", {
              required: true,
              pattern: /^[A-Za-z0-9-_]*$/,
              maxLength: 20,
              minLength: 3,
            })}
          />
          {/* Username validation errors */}
          {errors.username && (
            <p className="text-xs text-blue-500">
              {errors.username.type === "required" &&
                "Please fill out this field"}
              {errors.username.type === "pattern" &&
                "Only numbers and English letters are allowed"}
              {errors.username.type === "maxLength" && "Maximum 20 characters"}
              {errors.username.type === "minLength" &&
                "Please enter at least 3 characters"}
            </p>
          )}
          {/* Server errors for username */}
          {EditAssistantInformationErrors &&
            EditAssistantInformationErrors?.response?.data?.errors
              ?.username && (
              <p className="text-xs text-blue-500">
                {
                  EditAssistantInformationErrors?.response?.data?.errors
                    ?.username[0]
                }
              </p>
            )}
        </div>

        {/**  mobile field */}

        <div className="w-1/2 md:order-2 md:w-full">
          <div className="flex w-full flex-col items-start justify-center gap-2">
            {/* Assistant Number */}
            <label htmlFor="mobile">Assistant number</label>
            <div className="relative w-full">
              <input
                className="signin-inputs signin-input w-full"
                type="text"
                id="mobile"
                placeholder="Assistant number"
                name="mobile"
                autoComplete="on"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />
              <FontAwesomeIcon
                className="absolute bottom-1/2 right-4 w-5 h-5 translate-y-1/2"
                icon={faWhatsapp}
              />
            </div>
          </div>
          {/* Mobile number validation errors */}
          {errors.mobile && (
            <p className="mt-2 text-xs text-blue-500">
              {errors.mobile.type === "required" &&
                "Please fill out this field"}
              {errors.mobile.type === "pattern" &&
                "Enter your 11-digit phone number"}
              {errors.mobile.type === "maxLength" &&
                "Enter your 11-digit phone number"}
            </p>
          )}
          {/* Server errors for mobile number */}
          {EditAssistantInformationErrors &&
            EditAssistantInformationErrors?.response?.data?.errors?.mobile && (
              <p className="text-xs text-blue-500">
                {
                  EditAssistantInformationErrors?.response?.data?.errors
                    ?.mobile[0]
                }
              </p>
            )}
        </div>
      </div>

      <button className=" submit mt-6 w-full" type="submit">
        {EditAssistantInformationLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Modify information"
        )}
      </button>
    </form>
  );
};

export default AlrehlaInformations;
