import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setRef } from "yet-another-react-lightbox";

function AddEditExamVideos({
  edit,
  examID,
  reFetch,
  setReFetch,
  onCancel,
  isModalOpen,
}) {
  const [values, setValues] = useState(null);
  const navigate = useNavigate();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditVideo": {
        return {
          ...state,
          submitAddEditVideo: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditVideo: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_EXAM_VIDEOS_TAB_API
    : process.env.REACT_APP_ADMIN_ADD_EXAM_VIDEOS_TAB_API;

  //!--------- add edit Video -------

  const [VideoAddEditSuccess, VideoAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditVideo.flag,
    state.submitAddEditVideo.dependency,
    state.submitAddEditVideo.data,
    true
  );

  //!--------- get the Video info for editing -------

  const [VideoInfo, VideoInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_SINGLE_EXAM_VIDEOS_TAB_API}${examID}`,
    "GET",
    examID,
    examID
  );
  useEffect(() => {
    if (VideoInfo) {
      setValues({
        ...VideoInfo.data,
      });
    }
  }, [VideoInfo]);

  useEffect(() => {
    //!---add actions ----

    if (VideoAddEditSuccess) {
      if (setReFetch) {
        setReFetch(!reFetch);
      }

      if (onCancel) {
        onCancel();
      }
      if (!examID) {
        navigate(-1);
      }
      reset();
    }
  }, [VideoAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitAddEditVideo",
      payload: {
        flag: "AddEditVideo",
        dependency: !state.submitAddEditVideo.dependency,
        data: {
          ...data,
          id: examID,
        },
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Exam Videos | Nawar Academy"></HelmetTags>
      <div className={`form-container ${isModalOpen ? "" : "my-20"} flex w-full flex-col items-center`}>
        <h2 className="my-20 text-center text-3xl font-semibold">
          {examID
            ? "Edit Exam video data"
            : "Please fill in the information to add the Exam video"}
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className={`md:max-w-3/4 flex  ${
            isModalOpen ? "w-full" : "w-3/4"
          } flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50`}
        >
          {/** Name platform filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف الإنجليزية"}
                  {errors.name.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٤٠ حرف"}
                  {errors.name.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}

              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** vdo_id is_public*/}
          <div className="flex  w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" vdo_id flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="vdo_id">
                Youtube link
              </label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="vdo_id"
                placeholder="Youtube ID"
                name="vdo_id"
                autoComplete="on"
                {...register("youtube_id", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 155,
                  minLength: 3,
                })}
              />
              {errors.youtube_id && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.youtube_id.type === "required" &&
                    "Please fill out this field"}
                  {errors.youtube_id.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف الإنجليزية"}
                  {errors.youtube_id.type === "maxLength" &&
                    "أقصي عدد للحروف هو ١٥٥ حرف"}
                  {errors.youtube_id.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.youtube_id && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {
                        VideoAddEditErrors?.response?.data?.errors
                          ?.youtube_id[0]
                      }
                    </p>
                  )
              }
            </div>
            <div
              className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="vdo_type">
                Stage
              </label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
              </select>

              {errors.stage && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "أقصي عدد للحروف هو ١٥٥ حرف"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              VideoAddEditErrors &&
                VideoAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {VideoAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditVideo.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditVideo.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {examID ? "Confirm Edit video data" : "Add video"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditExamVideos;
