
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { useRef, useState } from "react";
import { Loader } from "../../../MainComponents";
import VideoCard from "../../../MainComponents/VideoCard";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useFade from "../../../MainComponents/Hooks/useFade";


function ExamVideos() {
    const TitleWrapper = useRef();
    const [fade] = useFade(TitleWrapper, 100);
  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  //!---- get all videos -------
  const [videosSuccess, videosErrors, videosLoading] = useAxios(
    process.env.REACT_APP_GET_EXAM_VIDEOS_TAB_API,
    "GET",
    "GET",
    
  );

  
  if (videosLoading) {
    return <Loader />;
  }
  

  return (
    <section
      className={`  h-auto  w-full   px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Exam Videos | Nawar Academy"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          All Exams Videos
        </h2>
      </div>
      <div
        onClick={() => setShowVideo((prev) => ({ ...prev, show: false }))}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center  /40 transition-all duration-300 ease-in-out ${
          showVideo.show === true
            ? "opacity- pointer-events-auto scale-100"
            : "opacity- pointer-events-none scale-0"
        } `}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
          <iframe
            src={showVideo.src}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameBorder="0"
            //style={{ aspectRatio: "16 / 9" }}
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
 

        <div className="videos video-grid-auto-fit w-full ">
          {videosSuccess?.videoExams?.length === 0 ? (
            <p>No Exam Videos Yet </p>
          ) : (
            videosSuccess?.videoExams?.map((video) => (
              <VideoCard
                setShowVideo={setShowVideo}
                exam
                video={video}
                key={video.key}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
}

export default ExamVideos;
